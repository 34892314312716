<template>
  <div>
    <UIExit exit-url="student-section-attendance" label="Exit Section Attendance detail" />
    <TitleWrapper
      :filter-option="false"
      title="SECTION_STUDENT_ATTENDANCE_DETAIL"
      tooltip-title="SECTION_STUDENT_ATTENDANCE_DETAIL"
      :display-breadcrumb="true"
    >
      <template v-slot:filterItems>
        <h2 v-i18n="result" class="mb-3 text-text-color font-roboto font-normal text-xl">Search</h2>
      </template>
    </TitleWrapper>
    <div class="main-content rounded-t-lg mt-5">
      <template v-if="isLoading">
        <Loader class="mt-20 h-64 pt-12 pb-40" :content="true" />
      </template>
      <div v-else-if="!isLoading && noRecord">
        <NoRecordFound />
      </div>

      <div v-else>
        <div class="bg-primary-white px-5 border border-primary-gray-200 rounded-lg py-2">
          <div class="py-3 flex">
            <div class="min-w-1 text-text-color">Title:</div>
            <div class="text-menu pl-4 capitalize">{{ attendanceDetail.title }}</div>
          </div>
          <div class="py-3 flex">
            <div class="min-w-1 text-text-color">Marked By:</div>

            <div class="text-menu pl-4 capitalize">
              {{ attendanceDetail.marked_by.first_name }} {{ attendanceDetail.marked_by.last_name }}
            </div>
          </div>
          <div class="py-3 flex">
            <div class="min-w-1 text-text-color">Date:</div>
            <div class="text-menu pl-4 capitalize">
              {{ $filters.getTimeOnly(attendanceDetail.marked_at) }}
            </div>
          </div>
        </div>
        <TableWrapper>
          <THead>
            <TRHead>
              <TH v-for="(head, index) in tableHeaders" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(attendance, index) in attandanceList" :key="index">
              <TD>
                <SingleUserDisplay :user="attendance" label="full_name" :image="true" />
              </TD>
              <TD>
                {{ (attendance.user && attendance.user.email) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                {{ attendance.comment || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                {{
                  $filters.getTimeOnly(
                    attendance.section_attendance.marked_at,
                    TIME_CONSTANTS.time12H,
                  )
                }}
              </TD>
              <TD>
                {{ $filters.getTimeOnly(attendance.inserted_at, TIME_CONSTANTS.DATE_TIME_12) }}
              </TD>
              <TD>
                <UiStatus :title="attendance.status" :success="attendance.status === PRESENT" />
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
      </div>
      <Pagination
        v-if="totalAttendanceCount > 10"
        v-show="!isLoading"
        :key="`ForceRender${forceRender}`"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(totalAttendanceCount, filteredRecordLimit)"
        @filterRecord="_getAttendanceDetail"
      />
    </div>
  </div>
</template>

<script>
import Loader from '@components/BaseComponent/Loader.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import generalMixin from '@src/mixins/general-mixins.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import TIME_CONSTANTS from '@src/constants/date-time-constants'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import scrollMixin from '@src/mixins/scroll-mixin'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import UIExit from '@/src/components/UiElements/UIExit.vue'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    SingleUserDisplay,
    Loader,
    TitleWrapper,
    Pagination,
    NoRecordFound,
    UIExit,
    UiStatus,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalMixin, scrollMixin],
  data() {
    return {
      TIME_CONSTANTS,
      PRESENT: 'present',
      forceRender: 0,
      result: 'result',
      title: 'title',
      dashboard: 'dashboard',
      totalAttendanceCount: 0,
      attendanceId: this.$route.params.id,
      options: ['Select'],
      tableHeaders: ['Student Name', 'Email', 'Comment', 'Attendance Time', 'Marked at', 'Status'],
      attendanceDetail: {},
      attandanceList: [],
      isLoading: false,
      noRecord: false,
      filteredRecordLimit: 10,
      GENERAL_CONSTANTS,
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
    }),
  },
  created() {
    this.setRightbar({})
    this._getAttendanceDetail()
  },
  methods: {
    /**
     * Exit Student mode and redirect to Student page
     * Remove current user from localStorage
     */
    async _getAttendanceDetail(range) {
      const payload = {
        skip: range?.skip || this.GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || this.GENERAL_CONSTANTS.RECORD_LIMIT,
        id: this.attendanceId,
      }
      this.isLoading = true
      const [res, err] = await this.getAttendanceOfSetionStudentBYId(payload)
      this.attendanceDetail = res.data.section_attendance
      this.totalAttendanceCount = res.data.meta.total_records
      this.attandanceList = res.data.records.map((record) => {
        record['full_name'] = `${record.user.first_name} ${record.user.last_name}`
        record['image'] = record?.user.image
        return record
      })
      this.setRightbar(res.data.meta)
      this.isLoading = false
      if (err) return
    },

    setRightbar(stat) {
      const rightBarContent = {
        header: {
          title: 'Attendance',
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'TOTAL_STUDENTS',
                value: stat?.total_students || GENERAL_CONSTANTS.EMPTY_RECORD,
                roles: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                  TENANT_ROLES.CAMPUS_ADMIN,
                ],
              },
              {
                text: 'TOTAL_PRESENT',
                value: stat?.present_count || GENERAL_CONSTANTS.EMPTY_RECORD,
              },
              {
                text: 'TOTAL_ABSENT',
                value: stat?.absent_count || GENERAL_CONSTANTS.EMPTY_RECORD,
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },

    ...mapActions('attendance', ['getAttendanceOfSetionStudentBYId']),
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.widthItem {
  min-width: 50%;
}
.minHieght80 {
  min-height: 80vh;
}
</style>
